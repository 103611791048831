.ant-cascader-menu-item-active {
  background-color: unset !important;
  font-weight: 700;
  color: #098256;
}

.ant-cascader-menu-item:hover {
  background-color: unset !important;
  font-weight: 700;
  color: #098256;
}
